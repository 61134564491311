<template>
  <div class="wrapper">
    <!-- Preloader -->
    <!-- <div class="preloader flex-column justify-content-center align-items-center">
          <img class="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
        </div> -->

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper bg-white">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <!-- /.col -->

            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header pc-bg">
                  <h3 class="card-title d-inline float-left text-white mt-2">
                    Add New Color
                  </h3>
                  <a
                    class="btn-sc-sm float-right add-btn"
                    data-toggle="modal"
                    data-target="#addModal"
                  >
                    <i class="fas fa-plus"></i>
                    <span style="font-size: 15px; font-weight: bold"> Add</span>
                  </a>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <!-- <table class="table text-center table-striped table-bordered">
                      <thead>
                        <tr>
                          <th style="width: 10px">#</th>

                          <th>Date</th>
                          <th>Holiday Name</th>
                          <th>Work Rate</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="holiday in holidays" :key="holiday">
                          <td>#</td>
                          <td>{{ holiday.date }}</td>
                          <td>{{ holiday.holiday_name }}</td>
                          <td>{{ holiday.rate }}</td>
  
                          <td>
                            <a @click.prevent="editdepartment(holiday.uuid)"   
                      data-toggle="modal"
                      data-target="#editModal" 
                              ><i class="fa-solid fa-pen-to-square"></i></a
                            >
  
                            <a  @click.prevent="deletedepartment(holiday.uuid)" class="-sm ml-2"
                              ><i class="fa-solid fa-trash text-red"></i></a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table> -->

                  <DataTable
                    :data="colors"
                    class="display table table-striped table-bordered mt-2"
                  >
                    <thead style="font-size: 15px">
                      <tr>
                        <th>ID</th>
                        <th>Colors Code</th>
                        <th>Colors Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style="font-size: 15px"></tbody>
                  </DataTable>
                </div>
                <!-- /.card-body -->
                <div class="card-footer clearfix"></div>
              </div>
              <!-- /.card -->

              <!-- /.card -->
            </div>
          </div>
        </div>
      </section>
      <!-- /.content -->
    </div>

    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add New Color</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="addForm">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label style="font-size: 18px">Color Code</label>
                    <input
                      type="text"
                      @input="errors.color_code = null"
                      v-model="color_code"
                      class="form-control"
                    />
                     <p class="text-danger mt-1" v-if="errors.color_code">
                      {{ errors.color_code[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label style="font-size: 18px">Color Name</label>
                    <input
                      type="text"
                      @input="errors.color_name = null"
                      v-model="color_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.color_name">
                      {{ errors.color_name[0] }}
                    </p>
                  </div>
                </div>
                
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-pc-sm" data-dismiss="modal">
              Cancle
            </button>
            <button @click.prevent="addcolor" type="button" class="btn-sc-sm">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Color</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <form ref="addForm">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label style="font-size: 18px">Color Code</label>
                    <input
                      type="text"
                      @input="errors.color_code = null"
                      v-model="color.color_code"
                      class="form-control"
                    />
                     <p class="text-danger mt-1" v-if="errors.color_code">
                      {{ errors.color_code[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label style="font-size: 18px">Color Name</label>
                    <input
                      type="text"
                      @input="errors.color_name = null"
                      v-model="color.color_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.color_name">
                      {{ errors.color_name[0] }}
                    </p>
                  </div>
                </div>
                
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-pc-sm" data-dismiss="modal">
              Cancle
            </button>
            <button @click.prevent="updatecolor(color.uuid)" type="button" class="btn-sc-sm">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>



    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Color</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body text-center">
            <h6>Are you sure you want to delete this color?</h6>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-pc-sm" data-dismiss="modal">
              Cancle
            </button>
            <button @click.prevent="delete_single" type="button" class="btn-sc-sm">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>



    
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
DataTable.use(DataTablesCore);
export default {
  components: { DataTable },

  data() {
    return {
      errors: {},
      colors: [],
      tes_data: [],

      color: {},
      uuid: "",
    };
  },
  methods: {
    getcolor() {
    axios
      .get("/v1/company/productColor")
      .then((response) => {
        if (response) {
          this.colors = [];
          var colors = response.data.data;
          for (var color of colors) {
            var uuid = color.uuid;
            var id = color.id;
            var color_code = color.color_code;
            var color_name = color.color_name;
            
            var action =
              "<a href='#' onclick='handleClick(" +
              '"' +
              uuid +
              '"' +
              ")' ' data-toggle='modal' data-target='#editModal'><i class='fa-solid fa-pen-to-square sc text-large '></i></a>   <a href='#' onclick='deletePopUp(" +
              '"' +
              uuid +
              '"' +
              ")' '  data-toggle='modal' data-target='#deleteModal'><i class='fa-solid fa-trash text-red ms-2 pc'></i></a> ";
            this.colors.push([
              id,
              color_code,
              color_name, 
              action,
            ]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
    addcolor() {
      axios
        .post("/v1/company/productColor", {
          color_code: this.color_code,
          color_name: this.color_name,
         
        })
        .then((response) => {
          if (response) {
            this.$refs.addForm.reset();

            this.color_code = "";
            this.color_name = "";

            this.getcolor();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },


    handleClick(uuid) {
      axios
        .get("/v1/company/productColor/" + uuid)
        .then((response) => {
          if (response) {
            this.color = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updatecolor(uuid) {
      axios
        .put("/v1/company/productColor/" + uuid, {

          color_code: this.color.color_code,
          color_name: this.color.color_name,
        })
        .then((response) => {
          if (response) {
            this.getcolor();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },


    delete_single() {
      axios
        .delete("/v1/company/productColor/" + this.uuid)
        .then((response) => {
          if (response) {
            this.getcolor();
            $("#deleteModal .close").click();
            
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },


    deletePopUp(uuid) {
      this.uuid = uuid;
    },

  },
  mounted: function () {
    this.getcolor();
    window.handleClick = this.handleClick;
    window.deletePopUp = this.deletePopUp;
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
