<template>
  <div id="app">
    <!-- Render the appropriate master view based on route's meta.layout -->
    <auth-master-view v-if="this.$route.meta.layout === 'auth'" />
    <company-master-view v-if="this.$route.meta.layout === 'company'" />
    
  </div>
</template>

<script>
import AuthMasterView from "@/components/masterview/AuthMasterView.vue";
import CompanyMasterView from "@/components/masterview/CompanyMasterView.vue";


export default {
  components: {
    AuthMasterView,
    CompanyMasterView,

  },
};
</script>

<style>
/* Your styles go here */
</style>
