<template>
  <div class="wrapper">
    <!-- Preloader -->
    <!-- <div class="preloader flex-column justify-content-center align-items-center">
          <img class="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
        </div> -->

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper bg-white">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <!-- /.col -->

            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header pc-bg">
                  <h3 class="card-title d-inline float-left text-white mt-2">
                    Add New Product
                  </h3>
                  <a
                    class="btn-sc-sm float-right add-btn"
                    data-toggle="modal"
                    data-target="#addModal"
                  >
                    <i class="fas fa-plus"></i>
                    <span style="font-size: 15px; font-weight: bold"> Add</span>
                  </a>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <!-- <table class="table text-center table-striped table-bordered">
                      <thead>
                        <tr>
                          <th style="width: 10px">#</th>

                          <th>Date</th>
                          <th>Holiday Name</th>
                          <th>Work Rate</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="holiday in holidays" :key="holiday">
                          <td>#</td>
                          <td>{{ holiday.date }}</td>
                          <td>{{ holiday.holiday_name }}</td>
                          <td>{{ holiday.rate }}</td>
  
                          <td>
                            <a @click.prevent="editdepartment(holiday.uuid)"   
                      data-toggle="modal"
                      data-target="#editModal" 
                              ><i class="fa-solid fa-pen-to-square"></i></a
                            >
  
                            <a  @click.prevent="deletedepartment(holiday.uuid)" class="-sm ml-2"
                              ><i class="fa-solid fa-trash text-red"></i></a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table> -->

                  <DataTable
                    :data="products"
                    class="display table table-striped table-bordered mt-2"
                  >
                    <thead style="font-size: 15px">
                      <tr>
                        <th>Product ID</th>
                        <th>Phone Name</th>
                        <th>Model</th>
                        <th>Specification</th>
                        <th>Color</th>
                        <th>Stock Status</th>
                        <th>Price</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style="font-size: 15px"></tbody>
                  </DataTable>

                  <!-- <DataTable
                        :data="emis"
  
                        class="display table table-striped table-bordered mt-2"
                      >
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>EMI Tenure</th>
                            <th>Interest Rate %</th>
                            <th>Process Fee %</th>
                            <th>Down Payment %</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                             <tr v-for="emi in emis" :key="emi.emi_id">
                        <td>{{ emi.emi_id }}</td>
                        <td>{{ emi.tenure }}</td>
                        <td>{{ emi.interest_rate }}</td>
                        <td>{{ emi.process_fee }}</td>
                        <td>{{ emi.down_payment }}</td>
                        <td>
                          <div class="action-buttons">
                            <button
                              @click="editEMI(emi)"
                              class="btn btn-warning btn-sm"
                            >
                              <i class="fas fa-edit"></i>
                              
                            </button>
                            <button
                              @click="deactivateEMI(emi)"
                              class="btn btn-secondary btn-sm"
                            >
                              <i class="fas fa-ban"></i>
                              
                            </button>
                          </div>
                        </td>
                      </tr>
                        </tbody>
                      </DataTable> -->
                </div>
                <!-- /.card-body -->
                <div class="card-footer clearfix"></div>
              </div>
              <!-- /.card -->

              <!-- /.card -->
            </div>
          </div>
        </div>
      </section>
      <!-- /.content -->
    </div>

    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add New Product</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="addForm">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Product ID</label>
                    <input
                      type="text"
                      @input="errors.product_id = null"
                      v-model="product_id"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.product_id">
                      {{ errors.product_id[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Phone Name</label>
                    <input
                      type="text"
                      @input="errors.phone_name = null"
                      v-model="phone_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.phone_name">
                      {{ errors.phone_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Model</label>
                    <input type="text" v-model="model" class="form-control" />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <div class="col-lg-4">
                  <!-- Dropdown to select colors -->
                  <div class="form-group">
                    <label style="font-size: 18px">Color</label>
                    <select
                      v-model="selectedColor"
                      class="form-control"
                      @change="addColor"
                    >
                      <option disabled value="">Select an option</option>
                      <option
                        v-for="color in colors"
                        :key="color.id"
                        :value="color.id"
                      >
                        {{ color.color_name }}
                      </option>
                    </select>
                  </div>

                  <!-- Display selected colors with a preview -->
                  <div v-if="selectedColors.length" class="mt-2">
                    <p>Selected Colors:</p>
                    <div class="selected-color-preview">
                      <span
                        v-for="colorId in selectedColors"
                        :key="colorId"
                        :style="{
                          backgroundColor: getColorById(colorId).color_code,
                          // padding: '5px',
                          marginRight: '5px',
                          marginBottom: '8px',
                          color: 'white',
                          borderRadius: '5px',
                          display: 'inline-flex',
                          alignItems: 'center',
                        }"
                      >
                        <!-- Color preview (a small circle of the selected color) -->
                        <span
                          :style="{
                            backgroundColor: getColorById(colorId).color_code,
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            display: 'inline-block',
                            marginRight: '5px',
                          }"
                        ></span>
                        <!-- Color name -->
                        {{ getColorById(colorId).color_name }}

                        <!-- Remove button -->
                        <button
                          @click="removeColor(colorId)"
                          style="
                            margin-left: 10px;
                            color: white;
                            background: transparent;
                            border: none;
                            font-weight: bold;
                            cursor: pointer;
                          "
                        >
                          &times;
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Brand</label>
                    <input
                      type="text"
                      v-model="brand_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Stock Status</label>
                    <select v-model="selectedStock" class="form-control">
                      <option disabled value="">Select an option</option>
                      <option value="1">Stock In</option>
                      <option value="2">Stock Out</option>
                    </select>
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label style="font-size: 18px">Description</label>
                    <ckeditor
                      style="height: 100px !important"
                      :editor="editor"
                      v-model="description"
                      :config="editorConfig"
                    ></ckeditor>
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Specification</label>
                    <select
                      v-model="newItem.specification"
                      class="form-control"
                    >
                      <option disabled value="">Select an option</option>
                      <option
                        v-for="variation in variations"
                        :key="variation.id"
                        :value="variation"
                      >
                        {{ variation.variant_name }}
                      </option>
                    </select>
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <!-- Base Price -->
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Base Price</label>
                    <input
                      type="text"
                      v-model="newItem.basePrice"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <!-- Promotion Price -->
                <div class="col-lg-3">
                  <div class="form-group">
                    <label style="font-size: 18px">Promotion Price</label>
                    <input
                      type="text"
                      v-model="newItem.promotionPrice"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <!-- Add Button -->
                <div class="col-1 pt-2">
                  <button
                    @click="addSpecification"
                    type="button"
                    class="btn btn-default btn-sm mt-4 mb-4"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>

                <div
                  v-if="specifications.length > 0"
                  style="max-height: 400px; overflow-y: auto"
                >
                  <table class="table text-center table-bordered">
                    <thead>
                      <tr>
                        <th style="width: 10px">#</th>
                        <th>Specification</th>
                        <th>Base Price</th>
                        <th>Promotion Price</th>
                        <th>Actions</th>
                        <!-- New Actions Column -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(specification, index) in specifications"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ specification.variant_name }}</td>
                        <td>{{ specification.basePrice }}</td>
                        <td>{{ specification.promotionPrice }}</td>
                        <td>
                          <!-- Delete Button -->
                          <button
                            @click="deleteSpecification(index)"
                            type="button"
                            class="btn btn-danger btn-sm"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-lg-8">
                  <div class="form-group">
                    <label style="font-size: 18px">Upload Images</label>
                    <input
                      type="file"
                      id="imageUpload"
                      class="form-control"
                      multiple
                      accept="image/*"
                      @change="handleFileSelect"
                    />
                    <div
                      v-if="imagePreviews.length > 0"
                      class="mt-2 d-flex flex-wrap"
                    >
                      <div
                        v-for="(image, index) in imagePreviews"
                        :key="index"
                        class="image-container"
                      >
                        <img
                          :src="image"
                          alt="Image Preview"
                          class="image-preview"
                        />
                        <button
                          @click="removeImage(index)"
                          class="btn btn-danger btn-sm remove-button"
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                    <p id="fileNames" class="text-info mt-1"></p>
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-pc-sm" data-dismiss="modal">
              Cancle
            </button>
            <button @click.prevent="addproduct" type="button" class="btn-sc-sm">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="viewModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Product Details</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Default box -->
            <div class="card card-solid">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <!-- Main Product Image -->
                    <div
                      v-if="
                        productdetails &&
                        productdetails.file &&
                        productdetails.file.length > 0
                      "
                    >
                      <img
                        :src="currentImage"
                        class="product-image"
                        alt="Product Image"
                      />
                    </div>
                    <div v-else>
                      <p>No product image available</p>
                    </div>

                    <!-- Thumbnail Images -->
                    <div class="col-12 product-image-thumbs">
                      <div
                        v-if="
                          productdetails &&
                          productdetails.file &&
                          productdetails.file.length > 0
                        "
                        class="product-image-thumbs"
                      >
                        <div
                          v-for="(file, index) in productdetails.file"
                          :key="index"
                          class="product-image-thumb"
                          :class="{
                            active: currentImage === getImageUrl(file.path),
                          }"
                          @click="changeImage(file.path)"
                        >
                          <img
                            :src="getImageUrl(file.path)"
                            alt="Product Image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <h3 class="my-3">
                      {{
                        productdetails.phone_name
                          ? productdetails.phone_name
                          : "N/A"
                      }}
                    </h3>
                    <p
                      v-html="
                        productdetails.description
                          ? productdetails.description
                          : 'N/A'
                      "
                    ></p>

                    <hr />
                    <h4>Available Colors</h4>

                    <p>
                      <template
                        v-if="
                          productdetails.color && productdetails.color.length
                        "
                      >
                        <span
                          v-for="(colorId, index) in productdetails.color"
                          :key="index"
                        >
                          <span v-if="colors.length > 0">
                            <!-- Find the color object using colorId -->
                            {{
                              colors.find((c) => c.id == colorId)?.color_name ||
                              "Unknown Color"
                            }}
                            <span v-if="index < productdetails.color.length - 1"
                              >,
                            </span>
                          </span>
                        </span>
                      </template>
                      <span v-else>N/A</span>
                    </p>
                    <!-- <div
                      class="btn-group btn-group-toggle"
                      data-toggle="buttons"
                    >
                      <label class="btn btn-default text-center active">
                        <input
                          type="radio"
                          name="color_option"
                          id="color_option_a1"
                          autocomplete="off"
                          checked
                        />
                        Green
                        <br />
                        <i class="fas fa-circle fa-2x text-green"></i>
                      </label>
                      <label class="btn btn-default text-center">
                        <input
                          type="radio"
                          name="color_option"
                          id="color_option_a2"
                          autocomplete="off"
                        />
                        Blue
                        <br />
                        <i class="fas fa-circle fa-2x text-blue"></i>
                      </label>
                      <label class="btn btn-default text-center">
                        <input
                          type="radio"
                          name="color_option"
                          id="color_option_a3"
                          autocomplete="off"
                        />
                        Purple
                        <br />
                        <i class="fas fa-circle fa-2x text-purple"></i>
                      </label>
                      <label class="btn btn-default text-center">
                        <input
                          type="radio"
                          name="color_option"
                          id="color_option_a4"
                          autocomplete="off"
                        />
                        Red
                        <br />
                        <i class="fas fa-circle fa-2x text-red"></i>
                      </label>
                      <label class="btn btn-default text-center">
                        <input
                          type="radio"
                          name="color_option"
                          id="color_option_a5"
                          autocomplete="off"
                        />
                        Orange
                        <br />
                        <i class="fas fa-circle fa-2x text-orange"></i>
                      </label>
                    </div> -->

                    <h3 class="my-3">Price</h3>
                    <div class="py-2">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Specification</th>
                            <th>Base Price</th>
                            <th>Promotional Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              spec, index
                            ) in productdetails.specification"
                            :key="index"
                          >
                            <td>{{ spec.variant_name }}</td>
                            <td>{{ spec.basePrice }}</td>
                            <td>{{ spec.promotionPrice }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <nav class="w-100">
                    <div class="nav nav-tabs" id="product-tab" role="tablist">
                      <a
                        class="nav-item nav-link active"
                        id="product-desc-tab"
                        data-toggle="tab"
                        href="#product-desc"
                        role="tab"
                        aria-controls="product-desc"
                        aria-selected="true"
                        >Specification</a
                      >
                      <!-- <a
                        class="nav-item nav-link"
                        id="product-comments-tab"
                        data-toggle="tab"
                        href="#product-comments"
                        role="tab"
                        aria-controls="product-comments"
                        aria-selected="false"
                        >Description</a
                      > -->
                      <!-- <a
                        class="nav-item nav-link"
                        id="product-rating-tab"
                        data-toggle="tab"
                        href="#product-rating"
                        role="tab"
                        aria-controls="product-rating"
                        aria-selected="false"
                        >Rating</a
                      > -->
                    </div>
                  </nav>
                  <div class="tab-content p-3" id="nav-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="product-desc"
                      role="tabpanel"
                      aria-labelledby="product-desc-tab"
                    >
                      <div class="section-box">
                        <!-- <h7 class="section-heading">Specification Details</h7> -->
                        <!-- <hr /> -->
                        <div class="row">
                          <div class="col-md-6">
                            <p><strong>Phone Name:</strong></p>
                            <p><strong>Model:</strong></p>
                            <p><strong>Brand:</strong></p>
                            <p><strong>RAM & ROM:</strong></p>
                            <p><strong>Colors:</strong></p>
                          </div>
                          <div class="col-md-6 border-left">
                            <p>
                              {{
                                productdetails.phone_name
                                  ? productdetails.phone_name
                                  : "N/A"
                              }}
                            </p>
                            <p>
                              {{
                                productdetails.model
                                  ? productdetails.model
                                  : "N/A"
                              }}
                            </p>
                            <p>Samsung</p>
                            <p>
                              <template
                                v-if="
                                  productdetails.specification &&
                                  productdetails.specification.length
                                "
                              >
                                <span
                                  v-for="(
                                    spec, index
                                  ) in productdetails.specification"
                                  :key="index"
                                >
                                  {{ spec.variant_name }}
                                  <span
                                    v-if="
                                      index <
                                      productdetails.specification.length - 1
                                    "
                                    >,
                                  </span>
                                </span>
                              </template>
                              <span v-else>N/A</span>
                            </p>

                            <p>
                              <template
                                v-if="
                                  productdetails.color &&
                                  productdetails.color.length
                                "
                              >
                                <span
                                  v-for="(
                                    colorId, index
                                  ) in productdetails.color"
                                  :key="index"
                                >
                                  <span v-if="colors.length > 0">
                                    <!-- Find the color object using colorId -->
                                    {{
                                      colors.find((c) => c.id == colorId)
                                        ?.color_name || "Unknown Color"
                                    }}
                                    <span
                                      v-if="
                                        index < productdetails.color.length - 1
                                      "
                                      >,
                                    </span>
                                  </span>
                                </span>
                              </template>
                              <span v-else>N/A</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="tab-pane fade"
                      id="product-comments"
                      role="tabpanel"
                      aria-labelledby="product-comments-tab"
                    >
                      Vivamus rhoncus nisl sed venenatis luctus. Sed condimentum
                      risus ut tortor feugiat laoreet. Suspendisse potenti.
                      Donec et finibus sem, ut commodo lectus. Cras eget neque
                      dignissim, placerat orci interdum, venenatis odio. Nulla
                      turpis elit, consequat eu eros ac, consectetur fringilla
                      urna. Duis gravida ex pulvinar mauris ornare, eget
                      porttitor enim vulputate. Mauris hendrerit, massa nec
                      aliquam cursus, ex elit euismod lorem, vehicula rhoncus
                      nisl dui sit amet eros. Nulla turpis lorem, dignissim a
                      sapien eget, ultrices venenatis dolor. Curabitur vel
                      turpis at magna elementum hendrerit vel id dui. Curabitur
                      a ex ullamcorper, ornare velit vel, tincidunt ipsum.
                    </div> -->
                    <!-- <div
                      class="tab-pane fade"
                      id="product-rating"
                      role="tabpanel"
                      aria-labelledby="product-rating-tab"
                    >
                      Cras ut ipsum ornare, aliquam ipsum non, posuere elit. In
                      hac habitasse platea dictumst. Aenean elementum leo augue,
                      id fermentum risus efficitur vel. Nulla iaculis malesuada
                      scelerisque. Praesent vel ipsum felis. Ut molestie, purus
                      aliquam placerat sollicitudin, mi ligula euismod neque,
                      non bibendum nibh neque et erat. Etiam dignissim aliquam
                      ligula, aliquet feugiat nibh rhoncus ut. Aliquam efficitur
                      lacinia lacinia. Morbi ac molestie lectus, vitae hendrerit
                      nisl. Nullam metus odio, malesuada in vehicula at,
                      consectetur nec justo. Quisque suscipit odio velit, at
                      accumsan urna vestibulum a. Proin dictum, urna ut varius
                      consectetur, sapien justo porta lectus, at mollis nisi
                      orci et nulla. Donec pellentesque tortor vel nisl commodo
                      ullamcorper. Donec varius massa at semper posuere. Integer
                      finibus orci vitae vehicula placerat.
                    </div> -->
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Product</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="addForm">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Product ID</label>
                    <input
                      type="text"
                      @input="errors.product_id = null"
                      v-model="showProducts.product_identity"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.product_id">
                      {{ errors.product_id[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Phone Name</label>
                    <input
                      type="text"
                      @input="errors.phone_name = null"
                      v-model="showProducts.phone_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.phone_name">
                      {{ errors.phone_name[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Model</label>
                    <input
                      type="text"
                      v-model="showProducts.model"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <div class="col-lg-4">
                  <!-- Dropdown to select colors -->
                  <div class="form-group">
                    <label style="font-size: 18px">Color</label>
                    <select
                      v-model="selectedColor"
                      class="form-control"
                      @change="addColor"
                    >
                      <option disabled value="">Select an option</option>
                      <option
                        v-for="color in colors"
                        :key="color.id"
                        :value="color.id"
                      >
                        {{ color.color_name }}
                      </option>
                    </select>
                  </div>

                  <!-- Display selected colors with a preview -->
                  <div v-if="selectedColors.length" class="mt-2">
                    <p>Selected Colors:</p>
                    <div class="selected-color-preview">
                      <span
                        v-for="colorId in selectedColors"
                        :key="colorId"
                        :style="{
                          backgroundColor: getColorById(colorId).color_code,
                          // padding: '5px',
                          marginRight: '5px',
                          marginBottom: '8px',
                          color: 'white',
                          borderRadius: '5px',
                          display: 'inline-flex',
                          alignItems: 'center',
                        }"
                      >
                        <!-- Color preview (a small circle of the selected color) -->
                        <span
                          :style="{
                            backgroundColor: getColorById(colorId).color_code,
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            display: 'inline-block',
                            marginRight: '5px',
                          }"
                        ></span>
                        <!-- Color name -->
                        {{ getColorById(colorId).color_name }}

                        <!-- Remove button -->
                        <button
                          @click="removeColor(colorId)"
                          style="
                            margin-left: 10px;
                            color: white;
                            background: transparent;
                            border: none;
                            font-weight: bold;
                            cursor: pointer;
                          "
                        >
                          &times;
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Brand</label>
                    <input
                      type="text"
                      v-model="brand_name"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Stock Status</label>
                    <select v-model="selectedStock" class="form-control">
                      <option disabled value="">Select an option</option>
                      <option value="1">Stock In</option>
                      <option value="2">Stock Out</option>
                    </select>
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label style="font-size: 18px">Description</label>
                    <ckeditor
                      style="height: 100px !important"
                      :editor="editor"
                      v-model="description"
                      :config="editorConfig"
                    ></ckeditor>
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <!-- <div class="col-lg-12">
                  <div class="form-group">
                    <label style="font-size: 18px">Description</label>
                    <ckeditor
                      style="height: 100px !important"
                      :editor="editor"
                      v-model="description"
                      :config="editorConfig"
                    ></ckeditor>
                    <p class="text-danger mt-1"></p>
                  </div>
                </div> -->

                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Specification</label>
                    <select
                      v-model="newItem.specification"
                      class="form-control"
                    >
                      <option disabled value="">Select an option</option>
                      <option
                        v-for="variation in variations"
                        :key="variation.id"
                        :value="variation"
                      >
                        {{ variation.variant_name }}
                      </option>
                    </select>
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <!-- Base Price -->
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Base Price</label>
                    <input
                      type="text"
                      v-model="newItem.basePrice"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <!-- Promotion Price -->
                <div class="col-lg-3">
                  <div class="form-group">
                    <label style="font-size: 18px">Promotion Price</label>
                    <input
                      type="text"
                      v-model="newItem.promotionPrice"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <!-- Add Button -->
                <div class="col-1 pt-2">
                  <button
                    @click="addSpecification"
                    type="button"
                    class="btn btn-default btn-sm mt-4 mb-4"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>

                <div
                  v-if="specifications.length > 0"
                  style="max-height: 400px; overflow-y: auto"
                >
                  <table class="table text-center table-bordered">
                    <thead>
                      <tr>
                        <th style="width: 10px">#</th>
                        <th>Specification</th>
                        <th>Base Price</th>
                        <th>Promotion Price</th>
                        <th>Actions</th>
                        <!-- New Actions Column -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(specification, index) in specifications"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ specification.variant_name }}</td>
                        <td>{{ specification.basePrice }}</td>
                        <td>{{ specification.promotionPrice }}</td>
                        <td>
                          <!-- Delete Button -->
                          <button
                            @click="deleteSpecification(index)"
                            type="button"
                            class="btn btn-danger btn-sm"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-lg-8">
                  <div class="form-group">
                    <label style="font-size: 18px">Upload Images</label>
                    <input
                      type="file"
                      id="imageUpload"
                      class="form-control"
                      multiple
                      accept="image/*"
                      @change="handleFileSelect"
                    />

                    <!-- Image Preview Section -->
                    <div
                      v-if="
                        imagePreviews.length > 0 || existingImages.length > 0
                      "
                      class="mt-2 d-flex flex-wrap"
                    >
                      <!-- Preloaded Existing Images -->
                      <div
                        v-for="(image, index) in existingImages"
                        :key="'existing-' + index"
                        class="image-container"
                      >
                        <img
                          :src="getImageUrl(image.path)"
                          alt="Existing Image"
                          class="image-preview"
                        />
                        <button
                          @click="removeExistingImage(index)"
                          class="btn btn-danger btn-sm remove-button"
                        >
                          &times;
                        </button>
                      </div>
                      <!-- New Uploaded Image Previews -->
                      <div
                        v-for="(image, index) in imagePreviews"
                        :key="'new-' + index"
                        class="image-container"
                      >
                        <img
                          :src="image"
                          alt="Image Preview"
                          class="image-preview"
                        />
                        <button
                          @click="removeImage(index)"
                          class="btn btn-danger btn-sm remove-button"
                        >
                          &times;
                        </button>
                      </div>
                    </div>

                    <p id="fileNames" class="text-info mt-1"></p>
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-pc-sm" data-dismiss="modal">
              Cancle
            </button>
            <button
              @click.prevent="updateproduct(showProducts.uuid)"
              type="button"
              class="btn-sc-sm"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Constant from "@/other/Constant";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import $ from "jquery";
import axios from "axios";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
DataTable.use(DataTablesCore);
export default {
  components: { DataTable },

  data() {
    return {
      existingImages: [],

      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        height: "100px",
      },

      selectedColor: "", // For the dropdown selection
      selectedColors: [], // Array to store selected colors

      // selectedColor: "", // Bind to the dropdown
      selectedVariation: "",
      selectedStock: "",
      // colors: [
      //   { value: "red", label: "Red" },
      //   { value: "blue", label: "Blue" },
      //   { value: "green", label: "Green" },
      //   { value: "yellow", label: "Yellow" },

      // ],

      // specification: [
      //   { value: "red", label: "4/64" },
      //   { value: "blue", label: "6/128" },
      //   { value: "green", label: "8/128" },

      // ],
      description: "",
      variations: [],
      colors: [],
      productdetails: [],

      newItem: {
        specification: "",
        basePrice: "",
        promotionPrice: "",
      },
      specifications: [], // To store the added data

      errors: {},
      products: [],
      tes_data: [],

      showProducts: {},
      uuid: "",
      files: [], // Array to hold selected files
      imagePreviews: [], // Array to hold image preview URLs
      currentImage: "",
    };
  },
  methods: {
    // getImageUrl(path) {
    //   return `http://119.40.92.105:8447/${path}`;
    // },

    getImageUrl(path) {
      return Constant.getImageUrl(path); // Reference the function from Constant
    },

    changeImage(newImagePath) {
      this.currentImage = Constant.getImageUrl(newImagePath);
    },

    addColor() {
      if (
        this.selectedColor &&
        !this.selectedColors.includes(this.selectedColor)
      ) {
        this.selectedColors.push(this.selectedColor); // Add selected color to the list
        this.selectedColor = ""; // Reset the dropdown
      }
    },

    // Method to remove a color from the selected colors array
    removeColor(colorId) {
      this.selectedColors = this.selectedColors.filter((id) => id !== colorId);
    },

    // Helper method to get color details by its ID
    getColorById(colorId) {
      return (
        this.colors.find((color) => color.id === colorId) || {
          color_name: "Unknown",
          color_code: "#000",
        }
      );
    },

    // addSpecification() {
    //   if (this.newItem.specification) {
    //     this.specifications.push({
    //       variant_name: this.newItem.specification.variant_name,
    //       basePrice: this.newItem.basePrice,
    //       promotionPrice: this.newItem.promotionPrice,
    //     });

    //     this.newItem.specification = "";
    //     this.newItem.basePrice = "";
    //     this.newItem.promotionPrice = "";
    //   }
    // },

    addSpecification() {
      if (this.newItem.specification) {
        this.specifications.push({
          variant_name: this.newItem.specification.variant_name,
          basePrice: this.newItem.basePrice,
          promotionPrice: this.newItem.promotionPrice,
        });

        // Reset form after adding
        this.newItem.specification = ""; // Reset to the initial value (not the object)
        this.newItem.basePrice = "";
        this.newItem.promotionPrice = "";
      }
    },

    deleteSpecification(index) {
      // Remove the specification from the array based on the index
      this.specifications.splice(index, 1);
    },

    handleFileSelect(event) {
      const newFiles = Array.from(event.target.files);
      this.files.push(...newFiles);

      // Generate previews for new files
      this.imagePreviews.push(
        ...newFiles.map((file) => URL.createObjectURL(file))
      );

      // Update file names display
      const fileNames = this.files.map((file) => file.name).join(", ");
      document.getElementById("fileNames").textContent =
        "Selected files: " + fileNames;
    },

    removeImage(index) {
      // Remove from new files and previews
      this.files.splice(index, 1);
      this.imagePreviews.splice(index, 1);

      // Update file names display
      const fileNames = this.files.map((file) => file.name).join(", ");
      document.getElementById("fileNames").textContent =
        "Selected files: " + fileNames;
    },

    removeExistingImage(index) {
      // Remove from existing images array
      this.existingImages.splice(index, 1);
    },

    getvariation() {
      axios
        .get("/v1/company/productSpecification/")
        .then((response) => {
          if (response) {
            this.variations = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getcolor() {
      axios
        .get("/v1/company/productColor/")
        .then((response) => {
          if (response) {
            this.colors = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getproduct() {
      axios
        .get("/v1/company/product/")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.products = [];
            var products = response.data.data;

            for (var product of products) {
              var uuid = product.uuid;
              var product_id = product.product_identity;
              var phone_name = product.phone_name;
              var model = product.model;

              // Handle color(s)
              var colorIds = product.color ? JSON.parse(product.color) : [];
              var colorDisplay = colorIds
                .map((colorId) => {
                  var selectedColor = this.colors.find((c) => c.id == colorId);
                  return selectedColor
                    ? `<span style="background-color: ${selectedColor.color_code}; padding: 5px; margin-right: 5px; color: white; border-radius: 5px;">${selectedColor.color_name}</span>`
                    : "No Color";
                })
                .join("");

              // Stock status display
              var stockStatus =
                product.stock_status == "1"
                  ? '<span style="padding: 5px; color: green; border-radius: 5px;">Stock In</span>'
                  : '<span style="padding: 5px; color: red; border-radius: 5px;">Stock Out</span>';

              // Parse the specification field
              var specificationArray = product.specification
                ? JSON.parse(product.specification)
                : [];
              var specification =
                specificationArray.length > 0
                  ? specificationArray
                      .map((spec) => spec.variant_name)
                      .join(", ")
                  : "N/A";

              // Get basePrice
              var basePrice =
                specificationArray.length > 0
                  ? specificationArray.map((spec) => spec.basePrice).join(", ")
                  : "N/A";

              var action =
                "<a href='#' onclick='viewDetails(" +
                '"' +
                uuid +
                '"' +
                ")' data-toggle='modal' data-target='#viewModal'><i class='fa-solid fa-eye text-green pc'></i></a> <a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' data-toggle='modal' data-target='#editModal'><i class='fa-solid fa-pen-to-square sc text-large ms-2 pc'></i></a>";

              //  +
              // "<a href='#' onclick='deletePopUp(" +
              // '"' +
              // uuid +
              // '"' +
              // ")' data-toggle='modal' data-target='#delete_client'><i class='fa-solid fa-ban text-red ms-2'></i></a>";

              // Push the product details to the products array
              this.products.push([
                product_id,
                phone_name,
                model,
                specification,
                colorDisplay,
                stockStatus,
                basePrice,
                action,
              ]);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    },

    addproduct() {
      // Create form data to send via API
      let formData = new FormData();

      // Add product details to the formData
      formData.append("product_id", this.product_id);
      formData.append("phone_name", this.phone_name);
      formData.append("model", this.model);

      // Convert selected colors array to a JSON string and append it
      if (this.selectedColors.length > 0) {
        formData.append("color", JSON.stringify(this.selectedColors));
      } else {
        formData.append("color", "[]"); // Send empty array if no colors are selected
      }

      formData.append("brand_name", this.brand_name);
      formData.append("stock_status", this.selectedStock);
      formData.append("description", this.description);

      // Convert specifications array to a JSON string and append it
      formData.append("specification", JSON.stringify(this.specifications));

      // Add uploaded files (images)
      if (this.files.length > 0) {
        // Loop through all files and append them to the FormData
        for (let i = 0; i < this.files.length; i++) {
          formData.append("file_name[]", this.files[i]); // Use "file_name[]" for multiple files
        }
      }

      // Make the API request to add the product
      axios
        .post("/v1/company/product/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response) {
            // alert("Product added successfully!");
            // Clear the form fields after submission
            this.product_id = "";
            this.phone_name = "";
            this.model = "";
            this.selectedColors = []; // Clear the selected colors
            this.brand_name = "";
            this.selectedStock = "";
            this.description = "";
            this.specification = [];
            this.files = [];
            this.imagePreviews = [];

            $("#addModal .close").click(); // Close modal
            this.getproduct();
          }
        })
        .catch((error) => {
          console.error("Error adding product:", error);
        });
    },

    handleClick(uuid) {
      axios
        .get("/v1/company/product/" + uuid)
        .then((response) => {
          if (response && response.data) {
            this.showProducts = response.data.data;
            this.selectedColors = JSON.parse(response.data.data.color || "[]"); // Assuming colors are stored as a JSON string
            this.specifications = response.data.data.specification
              ? JSON.parse(response.data.data.specification)
              : [];

            this.selectedStock = response.data.data.stock_status || "";
            this.description = response.data.data.description || "";

            this.existingImages = response.data.data.file || [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    viewDetails(uuid) {
      axios
        .get("/v1/company/product/" + uuid)
        .then((response) => {
          if (response) {
            this.productdetails = response.data.data;

            // Parse color if it's a JSON string
            if (this.productdetails.color) {
              this.productdetails.color = JSON.parse(this.productdetails.color);
            }

            // Parse the specification field
            if (this.productdetails.specification) {
              this.productdetails.specification = JSON.parse(
                this.productdetails.specification
              );
            }

            // Set the initial image URL from the product details
            if (
              this.productdetails.file &&
              this.productdetails.file.length > 0
            ) {
              this.currentImage = Constant.getImageUrl(
                this.productdetails.file[0].path
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // viewDetails(uuid) {
    //   axios
    //     .get("/v1/company/product/" + uuid)
    //     .then((response) => {
    //       if (response) {
    //         this.productdetails = response.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    updateproduct() {
      if (this.showProducts.uuid) {
        // Prepare the data for the PUT request
        const updatedProduct = {
          product_identity: this.showProducts.product_identity,
          phone_name: this.showProducts.phone_name,
          model: this.showProducts.model,
          color: JSON.stringify(this.selectedColors),
          brand_name: this.showProducts.brand_name,
          stock_status: this.selectedStock,
          description: this.description,
          specification: JSON.stringify(this.specifications),
          basePrice: this.newItem.basePrice,
          promotionPrice: this.newItem.promotionPrice,
        };

        // Send the PUT request
        axios
          .put(`/v1/company/product/${this.showProducts.uuid}`, updatedProduct)
          .then((response) => {
            if (response.data.success) {
              this.getproduct(); // Refresh product list
              this.$refs.addForm.reset(); // Reset form
              $("#editModal").modal("hide"); // Close modal
            } else {
              console.error("Update failed:", response.data.message);
            }
          })
          .catch((error) => {
            console.error(
              "Error updating product:",
              error.response ? error.response.data : error.message
            );
          });
      }
    },

    // updateemi(uuid) {
    //   axios
    //     .put("/v1/bank/emi/" + uuid, {
    //       duration: this.emi.duration,
    //       interest_rate: this.emi.interest_rate,
    //       process_fee: this.emi.process_fee,
    //       down_payment: this.emi.down_payment,
    //     })
    //     .then((response) => {
    //       if (response) {
    //         this.getproduct();

    //         $("#editModal .close").click();
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
  mounted: function () {
    this.getcolor();
    this.getvariation();
    this.getproduct();
    window.handleClick = this.handleClick;
    window.viewDetails = this.viewDetails;
  },
};
</script>

<style>
@import "datatables.net-bs5";

/* .image-container {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.image-preview {
  max-width: 200px;
  max-height: 200px;
}

.remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
} */

.remove-button:hover {
  background-color: darkred;
}

.section-box {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.section-heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
}

.section-box p {
  font-size: 16px;
  margin: 8px 0;
}

.border-left {
  border-left: 1px solid #ddd;
  padding-left: 20px;
}

.btn-pc-sm {
  background-color: #ff6f61;
  color: #fff;
  font-size: 16px;
}

.btn-sc-sm {
  background-color: #4caf50;
  color: #fff;
  font-size: 16px;
}

.btn-excel,
.btn-pdf {
  background-color: #2c6b2f; /* Excel button background color */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.btn-pdf {
  background-color: #e53e3e; /* PDF button background color */
}

.btn-excel:hover,
.btn-pdf:hover {
  opacity: 0.9;
}

.btn-excel .fa-file-excel,
.btn-pdf .fa-file-pdf {
  font-size: 1.5rem; /* Custom size for icons */
  margin-right: 8px; /* Space between icon and text */
}

.product-image {
  height: 450; /* Maintain aspect ratio */
  width: auto;
}

.product-image-thumb.active {
  border: 2px solid blue; /* Highlight active thumbnail */
}

.image-container {
  margin-right: 10px;
  position: relative;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
  padding: 5px;
}

.remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
}

.product-image {
  max-width: 80%;
  height: auto;
  width: 100%;
  max-height: 500px;
}

.product-image-thumbs {
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2rem;
}

/* .product-image-thumb {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1rem;
  max-width: 7rem;
  padding: 0.5rem;
}

.product-image-thumb img {
  max-width: 100%;
  height: auto;
  -ms-flex-item-align: center;
  align-self: center;
}

.product-image-thumb:hover {
  opacity: .5;
} */

/* .product-image,
.product-image-thumb img {
  width: 200px;  
  height: 200px;
  object-fit: cover;
}


.product-image-thumb {
  cursor: pointer; 
  margin-right: 5px; 
}

.product-image-thumb.active {
  border: 2px solid #007bff;
} */

.product-image-thumb img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border: 2px solid transparent;
  cursor: pointer;
}

.product-image-thumb.active img {
  border-color: #007bff;
}
</style>
