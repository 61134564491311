<template>
  <div class="wrapper">
    <!-- Preloader -->
    <!-- <div class="preloader flex-column justify-content-center align-items-center">
          <img class="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
        </div> -->

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper bg-white">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <!-- /.col -->

            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header pc-bg">
                  <h3 class="card-title d-inline float-left text-white mt-2">
                    Add Mobile Variations
                  </h3>
                  <a
                    class="btn-sc-sm float-right add-btn"
                    data-toggle="modal"
                    data-target="#addModal"
                  >
                    <i class="fas fa-plus"></i>
                    <span style="font-size: 15px; font-weight: bold"> Add</span>
                  </a>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <DataTable
                    :data="variations"
                    class="display table table-striped table-bordered mt-2"
                  >
                    <thead style="font-size: 15px">
                      <tr>
                        <th>ID</th>
                        <th>RAM</th>
                        <th>ROM</th>
                        <th>Specification</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style="font-size: 15px"></tbody>
                  </DataTable>
                </div>
                <!-- /.card-body -->
                <div class="card-footer clearfix"></div>
              </div>
              <!-- /.card -->

              <!-- /.card -->
            </div>
          </div>
        </div>
      </section>
      <!-- /.content -->
    </div>

    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Specification</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="addForm">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">RAM</label>
                    <input
                      type="text"
                      @input="updateVariation"
                      v-model="ram"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.ram">
                      {{ errors.ram[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">ROM</label>
                    <input
                      type="text"
                      @input="updateVariation"
                      v-model="rom"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.rom">
                      {{ errors.rom[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Variation</label>
                    <input
                      type="text"
                      :value="variation"
                      class="form-control"
                      disabled
                    />
                    <p class="text-danger mt-1" v-if="errors.variation">
                      {{ errors.variation[0] }}
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-pc-sm" data-dismiss="modal">
              Cancel
            </button>
            <button
              @click.prevent="addVariation"
              type="button"
              class="btn-sc-sm"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Set EMI</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <form ref="addForm">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">RAM</label>
                    <input
                      type="text"
                      @input="forupdateVariation"
                      v-model="vari.ram"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.interest_rate">
                      {{ errors.interest_rate[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">ROM</label>
                    <input
                      type="text"
                      @input="forupdateVariation"
                      v-model="vari.rom"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label style="font-size: 18px">Variation</label>
                    <input
                      type="text"
                      :value="variation"
                      class="form-control"
                      disabled
                    />
                    <p class="text-danger mt-1" v-if="errors.duration">
                      {{ errors.duration[0] }}
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-pc-sm" data-dismiss="modal">
              Cancle
            </button>
            <button
              @click.prevent="updatevariations(vari.uuid)"
              type="button"
              class="btn-sc-sm"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>



    <!-- Delete specification Modal -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Color</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body text-center">
            <h6>Are you sure you want to delete this color?</h6>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-pc-sm" data-dismiss="modal">
              Cancle
            </button>
            <button @click.prevent="delete_single" type="button" class="btn-sc-sm">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete specification Modal -->




  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
DataTable.use(DataTablesCore);
export default {
  components: { DataTable },

  data() {
    return {
      ram: "",
      rom: "",
      variation: "",
      errors: {},

      // errors: {},
      variations: [],
      tes_data: [],

      vari: {},
      uuid: "",
    };
  },
  methods: {
    getvariation() {
      axios
        .get("/v1/company/productSpecification/")
        .then((response) => {
          if (response) {
            this.variations = [];
            var variations = response.data.data;
            for (var variation of variations) {
              var uuid = variation.uuid;
              var id = variation.id;
              var ram = variation.ram + "GB";
              var rom = variation.rom + "GB";
              var variant_name = variation.variant_name;
             
              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-toggle='modal' data-target='#editModal'><i class='fa-solid fa-pen-to-square sc text-large '></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-toggle='modal' data-target='#deleteModal'><i class='fa-solid fa-trash text-red ms-2 pc'></i></a> ";
              this.variations.push([
                id,
                ram,
                rom,
                variant_name,
                action,
              ]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //     updateVariation() {

    //   if (this.ram && this.rom) {
    //     this.variation = `${this.ram}GB/${this.rom}GB`;
    //   } else {
    //     this.variation = '';  // Reset variation if any input is missing
    //   }
    //   console.log('RAM:', this.ram, 'ROM:', this.rom, 'Variation:', this.variation);
    // },

    updateVariation() {
      this.variation = `${this.ram}GB/${this.rom}GB`;
      
    },


    forupdateVariation() {
      this.variation = `${this.vari.ram}GB/${this.vari.rom}GB`;
      
    },



    addVariation() {
      // console.log('Sending data to API:', { ram: this.ram, rom: this.rom, variant_name: this.variation });
      axios
        .post("/v1/company/productSpecification/", {
          ram: this.ram,
          rom: this.rom,
          variant_name: this.variation,
        })
        .then((response) => {
          if (response) {
            // Reset the form and clear the inputs
            this.$refs.addForm.reset();
            this.ram = "";
            this.rom = "";
            this.variation = "";

            // Refresh the data if needed
            this.getvariation();
            $("#addModal .close").click(); // Close modal
          }
        })
        .catch((error) => {
          // Handle validation errors from API
          if (error.response && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            console.error("Something went wrong:", error);
          }
        });
    },

    handleClick(uuid) {
      axios
        .get("/v1/company/productSpecification/" + uuid)
        .then((response) => {
          if (response) {
            this.vari = response.data.data;
            this.forupdateVariation();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updatevariations(uuid) {
      // this.forupdateVariation(); 
      axios
        .put("/v1/company/productSpecification/" + uuid, {
          ram: this.vari.ram,
          rom: this.vari.rom,
          // variation: this.variant_name,
          variant_name: this.variation
          
        })
        .then((response) => {
          if (response) {
            this.getvariation();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete_single() {
      axios
        .delete("/v1/company/productSpecification/" + this.uuid)
        .then((response) => {
          if (response) {
            this.getvariation();
            $("#deleteModal .close").click();
            
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

     deletePopUp(uuid) {
      this.uuid = uuid;
    },
  },
  mounted: function () {
    this.getvariation();
    window.handleClick = this.handleClick;
     window.deletePopUp = this.deletePopUp;
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
