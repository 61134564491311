import { createRouter, createWebHistory } from "vue-router";

// Auth
import LoginView from "@/components/auth/LoginView.vue";
import RegisterView from "@/components/auth/RegisterView.vue"; // Import RegisterView

// company
import CompanyDashboardView from "@/components/company/CompanyDashboardView.vue";
import VariationView from "@/components/company/VariationView.vue";
import ColorView from "@/components/company/ColorView.vue";
import ProductView from "@/components/company/ProductView.vue";
import SettingsView from "@/components/company/SettingsView.vue";
import DeliveryView from "@/components/company/DeliveryView.vue";
import OthersView from "@/components/company/OthersView.vue";




const routes = [
  // Auth Route
  {
    path: "/",
    name: "login",
    meta: { layout: "auth" },
    component: LoginView,
  },
  {
    path: "/register",
    name: "register",
    meta: { layout: "auth" },
    component: RegisterView, // Register route added
  },


  // Company Route
  {
    path: "/company",
    name: "company",
    meta: { layout: "company" },
    children: [
      {
        path: "dashboard",
        name: "company-dashboard-view",
        component: CompanyDashboardView,
      },

      {
        path: "variations",
        name: "variation-list-view",
        component: VariationView,
      },

      {
        path: "colors",
        name: "color-view",
        component: ColorView,
      },

      {
        path: "products",
        name: "product-view",
        component: ProductView,
      },
      {
        path: "settings",
        name: "settings-view",
        component: SettingsView,
      },

      {
        path: "delivery",
        name: "delivery-view",
        component: DeliveryView,
      },

      {
        path: "others",
        name: "others-view",
        component: OthersView,
      },


    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
