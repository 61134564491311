<template>
  <div class="auth-container">
    <div class="row h-100">
      <div class="col-lg-6 h-100 auth-bannar">
        <div class="text-center mt-3">
          <!-- Logo container -->
          <div class="logo-container mb-5">
            <!-- Add margin-top for spacing -->

            <img src="/assets/images/logo/logo.png" class="logo" alt="Logo 1" />
            <img
              src="/assets/images/logo/ghashful.svg"
              class="logo"
              alt="Logo 2"
            />

            <img
              src="/assets/images/logo/samsung.svg"
              class="logo"
              alt="Logo 1"
            />
          </div>

          <img
            style="height: 70%; width: 70%"
            class="auth-image box-shadow mt-3"
            src="assets/images/resource/login.jpg"
          />
        </div>
      </div>

      <div class="col-lg-6 auth-form">
        <div class="auth-box">
          <h3 class="primary-color text-center">Login to Ghashful</h3>

          <form>
            <label class="m-20 form-lebel">Email</label>
            <input
              v-model="email"
              @input="clearError('email')"
              class="common-form"
              type="text"
            />

            <span id="email" class="error"></span>

            <label class="form-lebel mt-4">Password</label>

            <input
              v-model="password"
              @input="clearError('password')"
              class="common-form"
              type="password"
            />
            <span id="password" class="error"></span>
            <div class="mt-4">
              <!-- <span class="float-left">Remember me</span>
              <span class="float-right">Forgot Password?</span> -->
            </div>

            <button @click.prevent="login" class="mt-3 btn-100">LOGIN</button>
          </form>

          <!-- <h6 class="text-center mt-3">
            Don't have an account?<strong
              ><a href="/register" class="primary-color"> SIGNUP</a>
            </strong>
          </h6> -->
        </div>
      </div>
    </div>
    <!-- Footer Section -->
    <!-- <footer class="footer text-right">
      <div>
        <p>Powered by <a href="https://www.datafluent.xyz/"><strong>DataFluent Analytics</strong></a></p>
      </div>
    </footer> -->
  </div>
</template>

<script>
import { Validator } from "@/other/Validator";
import $ from "jquery";
import axios from "axios";

export default {
  name: "LoginView",

  data() {
    return {};
  },

  methods: {
    login() {
      var validator = new Validator();
      var error = validator.validated([
        { field: "email", value: this.email, type: "required" },

        {
          field: "password",
          value: this.password,
          type: "required|length",
          size: 4,
        },
      ]);

      if (error) {
        console.log(error);
      } else {
        //   if(this.email=="superadmin@gmail.com" && this.password=="superadmin"){

        //     this.$router.push({ path: 'superadmin' })
        // .then(() => {
        //   window.location.reload();
        // })
        // .catch(error => {
        //   console.error(error);
        // });

        //   }
        //   if(this.email=="company@gmail.com" && this.password=="company"){
        //     this.$router.push({ path: 'company' })
        // .then(() => {
        //   window.location.reload();
        // })
        // .catch(error => {
        //   console.error(error);
        // });

        //   }

        //   if(this.email=="employee@gmail.com" && this.password=="employee"){
        //     this.$router.push({ path: 'employee' })
        // .then(() => {
        //   window.location.reload();
        // })
        // .catch(error => {
        //   console.error(error);
        // });

        //   }

        axios
          .post("login", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            localStorage.setItem("user", JSON.stringify(response.data));

            // if (response.data.user.roles[0] == "bank") {
            //   this.$router
            //     .push({ name: "bank-dashboard-view" })
            //     .then(() => {
            //       window.location.reload();
            //     })
            //     .catch((error) => {
            //       console.error(error);
            //     });
            // }
            if (response.data.user.roles[0] == "company") {
              this.$router
                .push({ name: "delivery-view" })
                .then(() => {
                  // window.location.reload();
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          })
          .catch((error) => {
            this.$emit("get_message", "Someting went wrong!");

            console.log(error);

            // if(error.response.data.errors.password){
            //   $('#password').css("display","block")
            //   $('#password').html(error.response.data.errors.password[0])
            // }
            // if(error.response.data.errors.email){
            //   $('#email').css("display","block")
            //   $('#email').html(error.response.data.errors.email[0])
            // }
          });
      }
    },

    clearError(field) {
      $("#" + field).css("display", "none");
    },
  },
};
</script>

<style>
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.logo {
  width: 100px;
  height: auto;
  max-height: 100px;
}

/* .footer {
  background-color: #f8f9fa;
  padding: 10px 0; 
  position: relative; 
  bottom: 0; 
  width: 100%; 
} */
</style>
