<template>

<footer class="main-footer">
      <strong>Copyright &copy; 2024 <a href="#">Ghashful</a>.</strong>
      All rights reserved.
      <div class="float-right d-none d-sm-inline-block">
        <b>Powered By</b> <a href="https://www.datafluent.xyz/">Datafluent Analytics</a> 
      </div>
    </footer>
    
  
</template>

<script>
export default {

}
</script>

<style>

</style>