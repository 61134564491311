<template>
  <div class="wrapper">
    <!-- Preloader -->
    <!-- <div class="preloader flex-column justify-content-center align-items-center">
          <img class="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
        </div> -->

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper bg-white">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <!-- /.col -->

            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header pc-bg">
                  <h3 class="card-title d-inline float-left text-white mt-2">
                    Delivery Information
                  </h3>
                  <!-- <a
                    class="btn-sc-sm float-right add-btn"
                    data-toggle="modal"
                    data-target="#addModal"
                  >
                    <i class="fas fa-plus"></i>
                    <span style="font-size: 15px; font-weight: bold"> Add</span>
                  </a> -->
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <DataTable
                    :data="itemss"
                    class="display table table-striped table-bordered mt-2"
                  >
                    <thead style="font-size: 15px">
                      <tr>
                        <th>Customer ID</th>
                        <th>Product ID</th>
                        <th>Product Name</th>
                        <th>Model</th>
                        <th>IMEI</th>
                        <th>Shipping Date</th>
                        <th>Delivery Date</th>
                        <th>Delivered By</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style="font-size: 15px"></tbody>
                  </DataTable>

                  <!-- <DataTable
                        :data="emis"
  
                        class="display table table-striped table-bordered mt-2"
                      >
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>EMI Tenure</th>
                            <th>Interest Rate %</th>
                            <th>Process Fee %</th>
                            <th>Down Payment %</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                             <tr v-for="emi in emis" :key="emi.emi_id">
                        <td>{{ emi.emi_id }}</td>
                        <td>{{ emi.tenure }}</td>
                        <td>{{ emi.interest_rate }}</td>
                        <td>{{ emi.process_fee }}</td>
                        <td>{{ emi.down_payment }}</td>
                        <td>
                          <div class="action-buttons">
                            <button
                              @click="editEMI(emi)"
                              class="btn btn-warning btn-sm"
                            >
                              <i class="fas fa-edit"></i>
                              
                            </button>
                            <button
                              @click="deactivateEMI(emi)"
                              class="btn btn-secondary btn-sm"
                            >
                              <i class="fas fa-ban"></i>
                              
                            </button>
                          </div>
                        </td>
                      </tr>
                        </tbody>
                      </DataTable> -->
                </div>
                <!-- /.card-body -->
                <div class="card-footer clearfix"></div>
              </div>
              <!-- /.card -->

              <!-- /.card -->
            </div>
          </div>
        </div>
      </section>
      <!-- /.content -->
    </div>

    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add new EMI</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="addForm">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label style="font-size: 18px">EMI Tenure</label>
                    <input
                      type="text"
                      @input="errors.duration = null"
                      v-model="duration"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.duration">
                      {{ errors.duration[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label style="font-size: 18px">Interest Rate </label>
                    <input
                      type="text"
                      @input="errors.interest_rate = null"
                      v-model="interest_rate"
                      class="form-control"
                    />
                    <p class="text-danger mt-1" v-if="errors.interest_rate">
                      {{ errors.interest_rate[0] }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label style="font-size: 18px">Process Fee </label>
                    <input
                      type="text"
                      v-model="process_fee"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label style="font-size: 18px">Down Payment</label>
                    <input
                      type="text"
                      v-model="down_payment"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-pc-sm" data-dismiss="modal">
              Cancle
            </button>
            <button @click.prevent="addemi" type="button" class="btn-sc-sm">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="viewModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Product Details</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <section class="content">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <!-- Main content -->
                    <div class="invoice p-3 mb-3">
                      <!-- title row -->
                      <div class="row">
                        <div class="col-12 text-center">
                          <h4>
                            <img
                              src="/assets/images/logo/logo.png"
                              alt="Logo"
                              style="max-width: 100px; vertical-align: middle"
                            />
                            <!-- Image Tag Added -->
                          </h4>

                          <p style="margin-top: 10px">
                            Tower Alamgir, House - 11/B, Road - 55, <br />
                            Block - N.W (F), Gulshan - 2, Dhaka - 1212 <br />
                            Call Us: +8809610990120 <br />
                            <!-- Email:
                            <a href="mailto:info@company.com"
                              >query@excelbd.com</a
                            > -->
                          </p>
                        </div>

                        <!-- /.col -->
                      </div>
                      <!-- info row -->
                      <div class="row invoice-info">
                        <div class="col-sm-6 invoice-col">
                          <address>
                            <b>Customer ID:</b> {{ deliveryDetails.customer_id
                            }}<br />
                            <!-- <b>Product ID:</b> {{ deliveryDetails.product_id
                            }}<br /> -->
                            <b>Customer Name:</b> Abdul Karim<br />
                            <b>Contact:</b> +0881235522<br />
                            <b>Email:</b> example@gmail.com<br />
                            <b>Address:</b> {{ deliveryDetails.delivery_address
                            }}<br />
                          </address>
                        </div>
                        <!-- /.col -->
                        <!-- <div class="col-sm-4 invoice-col">
                          <address>
                            <strong>John Doe</strong><br />
                            795 Folsom Ave, Suite 600<br />
                            San Francisco, CA 94107<br />
                            Phone: (555) 539-1037<br />
                            Email: john.doe@example.com
                          </address>
                        </div> -->
                        <!-- /.col -->
                        <div class="col-sm-6 text-right">
                          <!-- <b>Invoice #007612</b><br />
                          <br /> -->
                          <!-- <b>Date:</b> 12/09/2024<br /> -->
                          <br />
                          <br />
                          <b>Purchase Date:</b>
                          {{ deliveryDetails.purchase_date }}<br />
                          <b>Shapping Date:</b>
                          {{ deliveryDetails.shipping_date }}<br />
                          <b>Delivery Date:</b>
                          {{ deliveryDetails.delivered_due_date }}<br />

                          <!-- <b>Payment Due:</b> 2/22/2014<br />
                          <b>Account:</b> 968-34567<br /> -->
                        </div>
                        <!-- /.col -->
                      </div>
                      <!-- /.row -->
                      <p class="lead">Device Details:</p>
                      <!-- Table row for Device Specification -->
                      <div class="row">
                        <div class="col-12 table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Phone Name</th>
                                <th>Brand</th>
                                <th>IMEI</th>
                                <th>Model</th>
                                <th>Color</th>
                                <th>Storage</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{{ deliveryDetails.product_id }}</td>
                                <td>{{ deliveryDetails.phone_name }}</td>
                                <td>{{ deliveryDetails.brand }}</td>
                                <td>{{ deliveryDetails.imei_no }}</td>
                                <td>{{ deliveryDetails.model }}</td>
                                <td>{{ deliveryDetails.color }}</td>
                                <td>{{ deliveryDetails.variation }}</td>
                                <td>{{ deliveryDetails.base_price }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- /.col -->
                      </div>
                      <!-- /.row -->

                      <div class="row">
                        <div class="col-12">
                          <p class="lead">Description:</p>
                          <p
                            class="text-muted well well-sm shadow-none"
                            style="margin-top: 10px"
                          >
                            {{ deliveryDetails.description }}
                          </p>
                        </div>
                      </div>

                      <div class="row">
                        <!-- accepted payments column -->
                        <div class="col-4">
                          <p>Status:</p>
                          <p
                            class="text-muted well well-sm shadow-none"
                            style="margin-top: 10px"
                          >
                            Delivered
                          </p>
                        </div>

                        <!-- /.col -->
                        <div class="col-8">
                          <p class="lead">Delivery Details:</p>

                          <div class="table-responsive">
                            <table class="table">
                              <tr>
                                <th class="text-left" style="width: 50%">
                                  Delivery Ref:
                                </th>
                                <td class="text-right">
                                  {{ deliveryDetails.delivery_ref_no }}
                                </td>
                              </tr>
                              <tr>
                                <th class="text-left">Delivery Address:</th>
                                <td class="text-right">
                                  {{ deliveryDetails.delivery_address }}
                                </td>
                              </tr>
                              <tr>
                                <th class="text-left">Delivery District:</th>
                                <td class="text-right">
                                  {{ deliveryDetails.delivery_district }}
                                </td>
                              </tr>
                              <tr>
                                <th class="text-left">Contact Person Name:</th>
                                <td class="text-right">
                                  {{
                                    deliveryDetails.delivery_contact_person_name
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th class="text-left">
                                  Contact Person Number:
                                </th>
                                <td class="text-right">
                                  {{ deliveryDetails.delivery_contact_person }}
                                </td>
                              </tr>
                              <tr>
                                <th class="text-left">Delivered By:</th>
                                <td class="text-right">
                                  {{ deliveryDetails.delivered_by }}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <!-- /.col -->
                      </div>
                      <!-- /.row -->

                      <!-- this row will not appear when printing -->
                      <div class="row no-print">
                        <div class="col-6 text-left">
                          <button
                            type="button"
                            class="btn btn-success"
                            style="margin-right: 5px"
                          >
                            <i class="far fa-credit-card"></i> Daily Report
                          </button>
                          <button type="button" class="btn btn-primary">
                            <i class="fas fa-download"></i> Weekly Report
                          </button>
                        </div>

                        <div class="col-6 text-right">
                          <button
                            type="button"
                            class="btn btn-warning"
                            @click="printModalContent"
                          >
                            <i class="fas fa-print"></i> Print
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- /.invoice -->
                  </div>
                  <!-- /.col -->
                </div>
                <!-- /.row -->
              </div>
              <!-- /.container-fluid -->
            </section>
            <!-- /.content -->
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Delivery Information</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <form ref="addForm">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label style="font-size: 18px">Set Imei Number</label>
                    <input
                      type="text"
                      v-model="delivery.imei_no"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label style="font-size: 18px">Shipping Date</label>
                    <input
                      type="Date"
                      v-model="delivery.shipping_date"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label style="font-size: 18px">Delivery Date</label>
                    <input
                      type="Date"
                      v-model="delivery.delivered_due_date"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label style="font-size: 18px">Delivery Partner</label>
                    <input
                      type="text"
                      v-model="delivery.delivered_by"
                      class="form-control"
                    />
                    <p class="text-danger mt-1"></p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-pc-sm" data-dismiss="modal">
              Cancle
            </button>
            <button
              @click.prevent="updatedelivery(delivery.uuid)"
              type="button"
              class="btn-sc-sm"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
DataTable.use(DataTablesCore);
export default {
  components: { DataTable },

  data() {
    return {
      errors: {},
      itemss: [],
      tes_data: [],

      delivery: {},
      deliveryDetails: [],
      uuid: "",
    };
  },
  methods: {
    getdelivery() {
      axios
        .get("/v1/company/productDelivery/")
        .then((response) => {
          if (response && Array.isArray(response.data.data)) {
            this.itemss = [];
            var itemss = response.data.data;
            for (var items of itemss) {
              var uuid = items.uuid;

              var customer_id = items.customer_id;
              var product_id = items.product_id;
              var phone_name = items.phone_name;
              var model = items.model;
              var imei_no = items.imei_no;

              var shipping_date = items.shipping_date;
              var delivered_due_date = items.delivered_due_date;
              var delivered_by = items.delivered_by;

              var status = items.status;
              var action =
                "<a href='#' onclick='viewClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-toggle='modal' data-target='#viewModal'><i class='fa-solid fa-eye text-green pc'></i></a> <a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-toggle='modal' data-target='#editModal'><i class='fa-solid fa-pen-to-square sc text-large ms-2 pc'></i></a>   <a href='#' onclick='(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-toggle='modal' data-target='#delete_client'><i class='fa-solid fa-trash text-red ms-2'></i></a> ";
              this.itemss.push([
                customer_id,
                product_id,
                phone_name,
                model,
                imei_no,
                shipping_date,
                delivered_due_date,
                delivered_by,
                status,
                action,
              ]);
            }
          } else {
            console.error("Data is not an array:", response.data.data);
          }
        })
        .catch((error) => {
          console.log("Error fetching delivery data:", error);
        });
    },

    addemi() {
      axios
        .post("/v1/company/productDelivery", {
          duration: this.duration,
          interest_rate: this.interest_rate,
          process_fee: this.process_fee,
          down_payment: this.down_payment,
        })
        .then((response) => {
          if (response) {
            this.$refs.addForm.reset();

            this.duration = "";
            this.interest_rate = "";
            this.process_fee = "";
            this.down_payment = "";

            this.getemi();
            $("#addModal .close").click();
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },

    handleClick(uuid) {
      axios
        .get("/v1/company/productDelivery/" + uuid)
        .then((response) => {
          if (response) {
            this.delivery = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    viewClick(uuid) {
      axios
        .get("/v1/company/productDelivery/" + uuid)
        .then((response) => {
          if (response) {
            this.deliveryDetails = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updatedelivery(uuid) {
      axios
        .put("/v1/company/productDelivery/" + uuid, {
          imei_no: this.delivery.imei_no,
          delivered_due_date: this.delivery.delivered_due_date,
          shipping_date: this.delivery.shipping_date,
          delivered_by: this.delivery.delivered_by,
        })
        .then((response) => {
          if (response) {
            this.getdelivery();

            $("#editModal .close").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // New print function
    printModalContent() {
      // Create a hidden iframe
      const iframe = document.createElement("iframe");
      iframe.style.display = "none"; // Hide the iframe
      document.body.appendChild(iframe);

      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(`
    <html>
      <head>
        <title>Print Product Details</title>
        <style>
          @media print {
            @page {
              size: A4; /* Set A4 paper size */
              margin: 20mm; /* Adjust margins as needed */
            }
            body {
              font-family: Arial, sans-serif;
              font-size: 10px; /* Smaller font size for body */
              margin: 0;
              padding: 0;
              color: #333;
            }
            h4, h5, h6 {
              margin: 0;
              font-size: 10px; /* Smaller font size for headings */
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 10px;
            }
            th, td {
              border: 1px solid #000;
              padding: 5px; /* Smaller padding */
              text-align: left;
              font-size: 9px; /* Smaller font size for table content */
            }
            p {
              font-size: 12px;
              font-weight: bold; 
            }

            .text-right {
                    text-align: right;
                    
            }

            .move-up {
              margin-top: -30px; /* Adjust as needed */
            }

            .move-down {
              margin-top: 30px; /* Adjust as needed */
            }

            .small-text {
                margin-top: 10px; 
                font-size: 10px; /* Adjust as needed */
                }



          }
        </style>
      </head>
      <body>
  <div class="invoice">
  <div class="row" style="display: flex; justify-content: center; text-align: center;">
  <div class="col-12">
    <h4>
      <img src="/assets/images/logo/logo.png" alt="Logo" style="max-width: 100px; vertical-align: middle; margin: 0 auto;"/>
    </h4>
    <p style="margin-top: 10px; text-align: center;">
      Tower Alamgir, House - 11/B, Road - 55, <br/> Block - N.W (F), 
       Gulshan - 2, Dhaka - 1212 <br />
      Call Us: +8809610990120 <br />
     
    </p>
  </div>
</div>

  <div class="row invoice-info">
  <div class="col-sm-6 invoice-col move-down">
    <address>
      <b>Customer ID:</b> ${this.deliveryDetails.customer_id}<br />
      <b>Customer Name:</b> Abdul Karim<br />
      <b>Contact:</b> +0881235522<br />
      <b>Email:</b> example@gmail.com<br />
      <b>Address:</b> ${this.deliveryDetails.delivery_address}<br />
    </address>
  </div>

  <!-- Applying the move-up class -->
  <div class="col-sm-6 text-right move-up">
    <b>Purchase Date:</b> ${this.deliveryDetails.purchase_date}<br />
    <b>Shipping Date:</b> ${this.deliveryDetails.shipping_date}<br />
    <b>Delivery Date:</b> ${this.deliveryDetails.delivered_due_date}<br />
  </div>
</div>


          <p class="lead">Device Details:</p>
          <div class="row">
            <div class="col-12 table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Phone Name</th>
                    <th>Brand</th>
                    <th>IMEI</th>
                    <th>Model</th>
                    <th>Color</th>
                    <th>Storage</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>${this.deliveryDetails.product_id}</td>
                    <td>${this.deliveryDetails.phone_name}</td>
                    <td>${this.deliveryDetails.brand}</td>
                    <td>${this.deliveryDetails.imei_no}</td>
                    <td>${this.deliveryDetails.model}</td>
                    <td>${this.deliveryDetails.color}</td>
                    <td>${this.deliveryDetails.variation}</td>
                    <td>${this.deliveryDetails.base_price}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="lead">Description:</p>
              <p class="small-text" style="margin-top: 10px">
                ${this.deliveryDetails.description}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <p class="lead">Delivery Details:</p>
              <div class="table-responsive">
                <table class="table">
                  <tr>
                    <th class="text-left" style="width: 50%">Delivery Ref:</th>
                    <td class="text-right">${this.deliveryDetails.delivery_ref_no}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Delivery Address:</th>
                    <td class="text-right">${this.deliveryDetails.delivery_address}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Delivery District:</th>
                    <td class="text-right">${this.deliveryDetails.delivery_district}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Contact Person Name:</th>
                    <td class="text-right">${this.deliveryDetails.delivery_contact_person_name}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Contact Person Number:</th>
                    <td class="text-right">${this.deliveryDetails.delivery_contact_person}</td>
                  </tr>
                  <tr>
                    <th class="text-left">Delivered By:</th>
                    <td class="text-right">${this.deliveryDetails.delivered_by}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-4">
              <p class="lead">Status:</p>
              
              <p class="text-muted well well-sm shadow-none" style="margin-top: 10px">
                Delivered
              </p>
            </div>

            <div class="col-12 move-down">
              <div class="text-center">
                <p>Note: This is computer generated document.</p>
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  `);

      iframeDoc.close();

      // Trigger the print with a timeout
      setTimeout(() => {
        iframe.contentWindow.focus(); // Focus on the iframe
        iframe.contentWindow.print(); // Trigger print dialog
        // Remove the iframe after printing
        iframe.parentNode.removeChild(iframe);
      }, 100); // Adjust the timeout duration as needed
    },
  },
  mounted: function () {
    this.getdelivery();
    window.handleClick = this.handleClick;
    window.viewClick = this.viewClick;
  },
};
</script>

<style>
@import "datatables.net-bs5";
</style>
