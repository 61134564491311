



const DELIVERY = "Delivery";
const CARRIER = "Carrier";
const COLLATION = "Collation";
const CASH_AND_CARRY = "Cash and carry";

const filebaseurl = "http://103.147.182.38/";

const getImageUrl = (path) => {
  return `${filebaseurl}${path}`;
};

export default {
  DELIVERY: DELIVERY,
  CARRIER: CARRIER,
  COLLATION: COLLATION,
  CASH_AND_CARRY: CASH_AND_CARRY,
  filebaseurl: filebaseurl,
  getImageUrl: getImageUrl // Exporting the function
};


// const DELIVERY = "Delivery"
// const CARRIER = "Carrier"
// const COLLATION = "Collation"
// const CASH_AND_CARRY = "Cash and carry"

// const filebaseurl="http://hrmback.com/" 
    
// export default {
//   DELIVERY: DELIVERY,
//   CARRIER: CARRIER,
//   COLLATION: COLLATION,
//   CASH_AND_CARRY: CASH_AND_CARRY,
//   filebaseurl:filebaseurl
// }
