<template>
  <aside
    class="main-sidebar sidebar-light-primary elevation-2"
    style="background-color: #ffffff"
  >
    <!-- Brand Logo -->
    <a href="#" class="brand-link d-flex justify-content-center">
      <img
        src="/assets/images/logo/logo.png"
        alt="DFIN Logo"
        class="img-fluid"
        style="max-height: 30px; width: auto"
      />
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2" style="padding-top: 30px">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'company-dashboard-view' }"
              exact-active-class="active"
            >
              <i class="nav-icon fas fa-home primary_color"></i>
              <p>Dashboard</p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'variation-list-view' }"
              exact-active-class="active"
            >
              <i class="nav-icon fas fa-random primary_color"></i>
              <p>Variations</p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'color-view' }"
              exact-active-class="active"
            >
              <i class="nav-icon fas fa-palette primary_color"></i>
              <p>Colors</p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'product-view' }"
              exact-active-class="active"
            >
              <i class="nav-icon fas fa-box primary_color"></i>
              <p>Products</p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'delivery-view' }"
              exact-active-class="active"
            >
              <i class="nav-icon fas fa-truck primary_color"></i>
              <p>Delivery</p>
            </router-link>
          </li>

          <!-- New Others Menu -->
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'others-view' }"
              exact-active-class="active"
            >
              <i class="nav-icon fas fa-file-alt primary_color"></i>
              <!-- Changed icon to 'fa-file-alt' -->
              <p>Reconcilation Report</p>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
export default {};
</script>

<style scoped>
/* Active link styling */
.nav-sidebar > .nav-item > .nav-link.active {
  background-color: #033a6b !important; /* Dark blue background for active link */
  color: #fff !important; /* White text color for better contrast */
}

/* Ensure active link retains its styles on hover */
.nav-sidebar > .nav-item > .nav-link.active:hover {
  background-color: #033a6b !important; /* Keep the same dark blue background on hover */
  color: #fff !important; /* Maintain white text color on hover */
}

/* Styling for hover effect on non-active links */
.nav-sidebar > .nav-item > .nav-link:hover {
  background-color: #bec0c3 !important; /* Light grey background on hover */
  color: #443636 !important; /* White text color on hover */
}

/* Menu text size customization */
.nav-link p {
  font-size: 17px; /* Adjust the size as needed */
}

/* Icon size customization */
.nav-icon {
  font-size: 20px; /* Adjust the icon size as needed */
}
</style>
