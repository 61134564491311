<template>
  <div>
    <NavbarView />
    <CompanySidebarView />
    <router-view/> 
    <FooterView />
  </div>
</template>

<script>
import NavbarView from '@/components/company/partial/NavbarView.vue'
import FooterView from '@/components/company/partial/FooterView.vue'
import CompanySidebarView from '@/components/sidebar/CompanySidebarView.vue'

export default {
  components: {
    NavbarView,
    CompanySidebarView,
    FooterView
  }
}
</script>

<style>
/* Your styles here */
</style>

