<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"
          ><i class="fas fa-bars"></i
        ></a>
      </li>

      <!-- <li class="nav-item d-none d-sm-inline-block">
        <a href="#" class="nav-link">Help</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a id="date" class="nav-link "> Sunday, 12-10-2023</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a id="clock" class="nav-link sc">  12:45 PM</a>
      </li> -->
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
          <i class="fas fa-th-large"></i>
        </a>
      </li> -->
      <li class="nav-item">
        <a @click.prevent="logout" class="nav-link" href="#">
          <!-- <img
      src="/assets/images/logo/user.png"
      alt="Logo"
      style="height: 40px;width: 40px;margin-top: -10px;border-radius: 50%;"
    /> -->
          <button class="btn btn-danger ml-2" style="margin-top: -5px">
            <i class="fa-solid fa-arrow-right-from-bracket mr-2"></i> Logout
          </button>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import Constant from "@/other/Constant";
export default {
  data() {
    return {
      logopath: "",
      basurl: Constant.filebaseurl,
    };
  },

  methods: {
    logout() {
      const user = JSON.parse(localStorage.getItem("user"));

      if (user) {
        localStorage.removeItem("user");

        this.$router.push({ name: "login" });
      }
    },
  },

  mounted: function () {
    var logo = JSON.parse(localStorage.getItem("user")).user.logo;
    if (logo != null) {
      this.logopath =
        Constant.filebaseurl +
        JSON.parse(localStorage.getItem("user")).user.logo.file.path;
    }
  },
};
</script>

<style></style>
